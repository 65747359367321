import React from 'react';
import { Link } from 'react-router-dom';
import * as Styled from '../styles';

const Prizes = () => (
    <>
        <li>
            Prizes
            <Styled.OrderedList>
                <li>
                    The Jackpot Competition Prize, Top Scorer Prize, Get 5
                    Bonus, Head to Head Challenge Prize and any Additional Prize
                    &#40;each defined below&#41; are together referred to as the
                    &quot;
                    <strong>Prizes</strong>&quot; and each a &quot;
                    <strong>Prize</strong>&quot;.
                </li>
                <li>There is no cash alternative to non&#45;cash prizes.</li>
                <li>
                    Unless agreed otherwise in writing the Prizes are
                    non&#45;refundable and non&#45;transferable.
                </li>
                <li>
                    The Promoter reserves the right to substitute the Prizes
                    &#40;or any part of them&#41; for a prize of equivalent or
                    greater monetary value if necessary for reasons beyond its
                    control.
                </li>
                <li>
                    The Promoter will not be liable for Prizes that do not reach
                    the winners for reasons beyond its reasonable control.
                </li>
                <li>
                    If any Super 6 fixture is postponed, abandoned or not
                    completed &#40;where less than 90mins is played&#41; the
                    fixture will be considered void.
                </li>
                <p>Jackpot Competition</p>
                <li>
                    The Jackpot Competition prize is &#163;250,000 unless
                    otherwise specified &#40;&quot;
                    <strong>Jackpot Competition Prize</strong>&quot;&#41; and
                    the winner will be determined as follows:
                    <Styled.OrderedList>
                        <li>
                            The Jackpot Competition Prize is awarded to the
                            player who correctly predicts the scores in all six
                            Super 6 fixtures in a relevant round.
                        </li>
                        <li>
                            If there are two or more players who have correctly
                            predicted the score of all six Super 6 fixtures, the
                            Jackpot Competition Prize will be won by the player
                            whose Golden Goal Prediction is closest to the
                            actual time of the first goal.
                        </li>
                        <li>
                            If two or more players correctly predicted the score
                            of all six Super 6 fixtures and their Golden Goal
                            Predictions are equally accurate, the Jackpot
                            Competition Prize will be split equally amongst
                            those players.
                        </li>
                        <li>
                            For players located in Ireland, all prizes will be
                            at an exchange rate of &#163;1:&#8364;1.
                        </li>
                    </Styled.OrderedList>
                </li>
                <p>Top Scorer Prize</p>
                <li>
                    Subject to the provisions below, the top scorer prize for
                    each round is &#163;5,000 unless otherwise specified
                    &#40;&quot;
                    <strong>Top Scorer Prize</strong>&quot;&#41; and shall be
                    determined as follows:
                    <Styled.OrderedList>
                        <li>
                            The Top Scorer Prize is awarded where there are no
                            players who correctly predict the scores in all six
                            Super 6 fixtures in a relevant round and no Jackpot
                            Competition Prize is awarded.
                        </li>
                        <li>
                            The Top Scorer Prize will be awarded to the player
                            who scores the highest number of points in a round
                            &#40;&quot;
                            <strong>Round Competition Winner</strong>
                            &quot;&#41;.
                        </li>
                        <li>
                            When the Jackpot Competition Prize is &#163;250,000
                            or more the Top Scorer Prize will be &#163;5,000 or
                            the equivalent sum in a holiday voucher. The prize
                            given is at the discretion of the promoter.
                        </li>
                        <li>
                            When the Jackpot Competition Prize is below
                            &#163;250,000 the Top Scorer Prize will be
                            &#163;1,000.
                        </li>
                        <li>
                            At least four or more Super 6 fixtures must be
                            completed during the relevant round in order for a
                            Top Scorer Prize for that round to be awarded.
                        </li>
                        <li>
                            If two or more players have the same number of
                            points, the winner will be the player whose Golden
                            Goal Prediction is closest to the actual time of the
                            first goal.
                        </li>
                        <li>
                            If two or more players Golden Goal Predictions from
                            Clause 5.6 were equally close to the time of the
                            first goal, the prize for that round will be split
                            equally amongst those players.
                        </li>
                        <li>
                            An additional bonus of &#163;1,000 &#40;unless
                            advertised otherwise&#41; &#40;&quot;
                            <strong>Get 5 Bonus</strong>&quot;&#41; will be
                            awarded if the Round Competition Winner has
                            correctly predicted the scores for five of the Super
                            6 fixtures that round.
                        </li>
                        <li>
                            In the case of ties as referred to clause 5.6 above,
                            where the tied entrants each correctly predict the
                            scores for five of the Super 6 fixtures and have
                            equally close Golden Goal Predictions, the Get 5
                            Bonus will be split equally between all winners of
                            the round.
                        </li>
                        <li>
                            Delivery of the holiday voucher Prize will be
                            managed by NFE, a third party supplier. Data will be
                            shared with NFE solely to the extent necessary for
                            the purpose of fulfilment of the Prize. NFE will
                            also be in touch via email or phone call. Customers
                            are responsible for ensuring the contact details on
                            their account are up to date.
                        </li>
                        <li>
                            Customers who win holiday vouchers are not entitled
                            to exchange this Prize for cash or any other
                            alternative and may choose to spend their voucher
                            subject to the terms and conditions which may be
                            imposed by NFE Events.
                        </li>
                        <li>
                            Please note that if the winner plans a holiday
                            exceeding the amount won from the Prize, the
                            remaining balance will be the winner&#39;s
                            responsibility to pay and manage.
                        </li>
                        <li>
                            If NFE Events are unable to subsequently contact the
                            winning Customer to confirm the email address or
                            make any arrangements in relation to the Prize
                            fulfilment, despite reasonable attempts to do so,
                            the Promotor reserves the right to disqualify that
                            Player winner, in which case they will forfeit any
                            Prize.
                        </li>
                        <li>
                            Players who win any Prize must be over 18 years of
                            age. For certain events there may be restrictions on
                            the age of guests at the discretion of NFE Events or
                            event organisers/suppliers.
                        </li>
                        <li>
                            If the Promoter has been unable to contact the
                            winner of the Prize within 14 days of that game
                            period or the competition ending the winner shall
                            forfeit the Prize and the Promoter can expire the
                            Prize.
                        </li>
                        <li>
                            For players located in Ireland, all prizes will be
                            at an exchange rate of &#163;1:&#8364;1.
                        </li>
                    </Styled.OrderedList>
                </li>
                <p>Head to Head Challenge</p>
                <li>
                    Players are automatically opted into a Head to Head
                    challenge via the Website or Mobile Apps prior to the
                    deadline for submitting entries for that round &#40;&quot;
                    <strong>Head to Head Challenge</strong>&quot;&#41;.
                </li>
                <li>
                    Once predictions have been submitted for that round, players
                    shall automatically gain entry into the Head to Head
                    Challenge prize draw if they obtain a higher number of
                    points in any round &#40;calculated as per clause 5.4&#41;
                    than the Head to Head opponent for that round.
                </li>
                <li>
                    The Head to Head Challenge Prizes shall be awarded to
                    players who are placed into the Head to Head Challenge prize
                    draw and selected at random by a computer program shortly
                    after the completion of a round.
                </li>
                <li>
                    Head to Head Challenge Prizes will be credited within four
                    weeks of verification of the winning player in accordance
                    with the verification process detailed in clause 9 &#40;
                    <Styled.EmphasisedText>
                        Winners and Entrants Verification
                    </Styled.EmphasisedText>
                    &#41; below.
                </li>
                <li>
                    In the event that a player&apos;s Head to Head Challenge is
                    terminated due to a decision by the Promoter pursuant to
                    clause 6.16 of these Terms and Conditions, each player to
                    the relevant Head to Head Challenge will be removed from the
                    Head to Head Challenge.
                </li>
                <li>
                    The Head to Head opponent may change their predictions
                    before the closing time for a round via the Website or
                    Mobile Apps. Once a round has closed for entries, the Head
                    to Head opponent shall not change their predictions.
                </li>
                <li>
                    In the event there is no submission of the Head to Head
                    opponent&apos;s predictions for a round, the Head to Head
                    Challenge shall be deemed void for that round.
                </li>
                <li>
                    The Promoter reserves the right to suspend, modify, remove
                    and/or add a Head to Head Challenge &#40;including the Head
                    to Head Challenge Prizes&#41; at its sole discretion with
                    immediate effect and without notice.
                </li>
                <li>
                    The Head to Head Challenge Prize/Prizes will be won by a
                    select number of players who can outscore the Head to Head
                    opponent in that specific round and could consist of cash or
                    non&#45;cash prizes. The value of any cash prize shall never
                    be lower than &#163;100.
                </li>
                <p>Additional Prizes</p>
                <li>
                    Additional prizes and/or specials &#40;&quot;
                    <strong>Additional Prizes</strong>&quot;&#41; may be
                    introduced at any time during the Competition at the sole
                    discretion of the Promoter.
                </li>
                <li>
                    The Additional Prizes will be in addition to the Jackpot
                    Competition Prize, Top Scorer Prize, Get 5 Bonus and Head to
                    Head Challenge Prize outlined above.
                </li>
                <li>
                    In the event that Additional Prizes are introduced, the
                    Promoter will publish and notify players of any additional
                    terms and conditions as may be applicable to those
                    Additional Prizes.
                </li>
                <li>
                    For players located in Ireland, all prizes will be at an
                    exchange rate of &#163;1:&#8364;1.
                </li>
                <li>
                    <p>League Champion</p>
                    <Styled.OrderedList>
                        <li>
                            By playing any Super 6 round in the 24/25 season you
                            opt into the chance of winning this prize. Round
                            schedules can be found on the app or website and are
                            at the discretion of the promoter.
                        </li>
                        <li>
                            This competition will run from from Wednesday 24th
                            July 2024 at 12:00 until 30th May 2025 at 23:59pm.
                            &#40;&quot;the Promotional Period&quot;&#41;. Any
                            entries made outside of the Promotional Period will
                            not qualify.
                        </li>
                        <li>
                            To win you must finish top of the Super 6
                            leaderboard for the 24/25 season. This can be found
                            in Leaderboard within the app on season view. To
                            finish top you must build points through correctly
                            predicting scores and results throughout the season.
                            Your overall score is a combination of both correct
                            results and correct scores. A correct score is
                            valued at 5 points, a correct result is valued at 2
                            points. These can be tracked via the Super 6 app or
                            via our website &#45;
                            <Link to="/">https://super6.skysports.com/</Link>.
                        </li>
                        <li>
                            The prize available to first place is &#163;10,000
                            &#40;&quot;the Prize&quot;&#41;. For players using
                            Euros, all prizes will be at an exchange rate of
                            &#163;1:&#8364;1.
                        </li>
                        <li>
                            There is a maximum of 1 Prize available as part of
                            this competition.
                        </li>
                        <li>
                            The winner will be announced no later than 23:59 on
                            30 May 2025.
                        </li>
                        <li>
                            Cash prizes will be delivered in the form of a
                            prepaid card which can be used for purchases online,
                            over the phone, and in&#45;store, withdrawals can be
                            made at ATM locations worldwide wherever Mastercard
                            or Visa are accepted also. As soon as the card is
                            created and funds loaded, the user will receive an
                            email with instructions on retrieving their new card
                            details. This card can be added to Google Pay and
                            Apple wallets.
                        </li>
                        <li>
                            The winner will be the player who is top of the
                            Super 6 leaderboard at the end of the season. The
                            final round will take place on the final day of the
                            Premier League. Following this the winner will be
                            confirmed before 23:59 on the 30 May 2025.
                        </li>
                        <li>
                            The winner will be notified by email to the email
                            address registered on their account. Customers are
                            responsible for ensuring the contact details on
                            their account are up to date.
                        </li>
                        <li>
                            If the Promoter has been unable to contact the
                            winner of the Prize within 14 days of that game
                            period or competition ending the winner shall
                            forfeit the prize and the Promoter can award the
                            prize to second place.
                        </li>
                        <li>
                            Once a winner has responded to the Promoter and
                            verified their details to the Promoter&apos;s
                            satisfaction, the winner will receive their prize
                            within 28 days.
                        </li>
                        <li>
                            No entries on behalf of any third parties shall be
                            accepted.
                        </li>
                        <li>
                            {' '}
                            The Promoter has the right, at their reasonable
                            discretion, to alter, amend or terminate this
                            competition acting reasonably and with prior notice.
                        </li>
                        <li>
                            If any provision or part&#45;provision of these
                            terms and conditions are or becomes invalid, illegal
                            or unenforceable, it shall be deemed modified to the
                            minimum extent necessary to make it valid, legal and
                            enforceable. If such modification is not possible,
                            the relevant provision or part&#45;provision shall
                            be deemed deleted. Any modification to or deletion
                            of a provision or part&#45;provision under this
                            clause shall not affect the validity and
                            enforceability of the rest of these terms and
                            conditions.
                        </li>
                        <li>
                            The Competition is co&#45;promoted by Sky UK Limited
                            of Grant Way, Isleworth, Middlesex, TW7 5QD
                            &#40;&quot;
                            <strong>Sky</strong>&quot;&#41; and Hestview Limited
                            &#40;trading as Sky Games&#41; with a registered
                            office address of One Chamberlain Square Cs,
                            Birmingham, United Kingdom, B3 3AX. &#40;Sky and Sky
                            Games together being, the &quot;Promoter&quot;&#41;.
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    <p>Official Club Leagues</p>
                    <Styled.OrderedList>
                        <li>
                            By playing any Super 6 round in the 24/25 season you
                            opt into the chance of winning this prize. Round
                            schedules can be found on the app or website and are
                            at the discretion of the Promoter.
                        </li>
                        <li>
                            This competition will run from Wednesday 24th July
                            2024 at 12:00 until 30th May 2025 at 23:59pm.
                            &#40;the &quot;Promotional Period&quot;&#41;. Any
                            entries made outside of the Promotional Period will
                            not qualify. Eligible winners must have joined the
                            official club league before 23:59 March 31, 2025.
                            Official club leagues can be located via the menu on
                            the app or website within the leagues section. You
                            can join your official club league from this page by
                            clicking &apos;join league&apos;.{' '}
                        </li>
                        <li>
                            To win you must finish top of the leaderboard in
                            official club league Super 6 for the 24/25 season.
                            To finish top you must build points through
                            correctly predicting scores and results throughout
                            the season. Your overall score is a combination of
                            both correct results and correct scores. A correct
                            score is valued at 5 points, a correct result is
                            valued at 2 points. These can be tracked via the
                            Super 6 app or via our website &#45;
                            <Link to="/">https://super6.skysports.com/</Link>.
                        </li>
                        <li>
                            The prize available to first place of each club
                            league is the club shirt from that season &#40;the
                            &quot;Prize&quot;&#41;. For Championship, League One
                            and League Two official club leagues if you win your
                            club league and your club reaches a playoff final at
                            Wembley you will also win 2 tickets to this event.{' '}
                        </li>
                        <li>
                            You can only win one official club league, the
                            league you join at the earliest date will be the
                            league you will be eligible for. There is only one
                            winner per league.{' '}
                        </li>
                        <li>
                            The winners will be announced no later than 14 days
                            after the Super 6 24/25 season has finished, 23:59
                            30th May.
                        </li>
                        <li>
                            The winner will be notified by email to the email
                            address registered on their account. Customers are
                            responsible for ensuring the contact details on
                            their account are up to date.
                        </li>
                        <li>
                            Delivery of the Prize will be managed by a
                            third&#45;party supplier &#40;Prize Shark&#41;. Data
                            will be shared with Prize Shark third parties solely
                            to the extent necessary for the purpose of
                            fulfilment of the Prize. They will also be in touch
                            via email or phone call. Customers are responsible
                            for ensuring the contact details on their account
                            are up to date.
                        </li>
                        <li>
                            If the Promoter has been unable to contact the
                            winner of the Prize within 14 days of that game
                            period or competition ending the winner shall
                            forfeit the prize and the Promoter can award the
                            prize to second place.
                        </li>
                        <li>
                            Once a winner has responded to the Promoter and
                            verified their details to the Promoter&apos;s
                            satisfaction, the winner will receive their prize
                            within 28 days.
                        </li>
                        <li>
                            No entries on behalf of any third parties shall be
                            accepted.
                        </li>
                        <li>
                            For players located in Ireland, all prizes will be
                            at an exchange rate of &#163;1:&#8364;1.
                        </li>
                        <li>
                            The Promoter has the right, at their reasonable
                            discretion, to alter, amend or terminate this
                            competition acting reasonably and with prior notice
                            .
                        </li>
                        <li>
                            If any provision or part&#45;provision of these
                            terms and conditions are or becomes invalid, illegal
                            or unenforceable, it shall be deemed modified to the
                            minimum extent necessary to make it valid, legal and
                            enforceable. If such modification is not possible,
                            the relevant provision or part&#45;provision shall
                            be deemed deleted. Any modification to or deletion
                            of a provision or part&#45;provision under this
                            clause shall not affect the validity and
                            enforceability of the rest of these terms and
                            conditions.
                        </li>
                        <li>
                            The Competition is co&#45;promoted by Sky UK Limited
                            of Grant Way, Isleworth, Middlesex, TW7 5QD
                            &#40;&quot;
                            <strong>Sky</strong>&quot;&#41; and Hestview Limited
                            &#40;trading as Sky Games&#41; with a registered
                            office address of One Chamberlain Square Cs,
                            Birmingham, United Kingdom, B3 3AX. &#40;Sky and Sky
                            Games together being, the &quot;Promoter&quot;&#41;.
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    <p>The 100 Club</p>
                    <Styled.OrderedList>
                        <li>
                            By playing any Super 6 round in the 24/25 season you
                            opt into the chance of winning this prize. Round
                            schedules can be found on the app or website and are
                            at the discretion of the Promoter.
                        </li>
                        <li>
                            This competition will run from Wednesday 24th July
                            2024 at 12:00 until 30th May 2025 at 23:59pm.
                            &#40;the &quot;Promotional Period&quot;&#41;. Any
                            entries made outside of the Promotional Period will
                            not qualify.
                        </li>
                        <li>
                            To win you must predict a score whereby 100 players
                            or less predict the correct score from any Super 6
                            fixture in a given round. This prize will be
                            available every round.{' '}
                        </li>
                        <li>
                            The cash prizes available to winners will depend on
                            the number of players predicting the same scoreline
                            correctly. This is shown in the table below
                            &#40;24.5&#41;. For example if 14 players correctly
                            predict the score they will each win &#163;500. If
                            82 predict the correct score they are entered into a
                            prize draw to win &#163;1,000. All winners of 100
                            Club will receive a Super 6 100 club mug.
                        </li>
                        <li>
                            Table to indicate the prize rewarded based on how
                            many players correctly predicted the scoreline.
                            <Styled.RolloverTable>
                                <tr>
                                    <th>Players who predicted correctly</th>
                                    <th>Cash Prize</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>&#163;10,000</td>
                                </tr>
                                <tr>
                                    <td>2&#45;10</td>
                                    <td>&#163;1,000</td>
                                </tr>
                                <tr>
                                    <td>11&#45;20</td>
                                    <td>&#163;500</td>
                                </tr>
                                <tr>
                                    <td>21&#45;30</td>
                                    <td>&#163;200</td>
                                </tr>
                                <tr>
                                    <td>31&#45;40</td>
                                    <td>&#163;1,000 Prize Draw</td>
                                </tr>
                                <tr>
                                    <td>41&#45;50</td>
                                    <td>&#163;1,000 Prize Draw</td>
                                </tr>
                                <tr>
                                    <td>51&#45;60</td>
                                    <td>&#163;1,000 Prize Draw</td>
                                </tr>
                                <tr>
                                    <td>61&#45;70</td>
                                    <td>&#163;1,000 Prize Draw</td>
                                </tr>
                                <tr>
                                    <td>71&#45;80</td>
                                    <td>&#163;1,000 Prize Draw</td>
                                </tr>
                                <tr>
                                    <td>81&#45;90</td>
                                    <td>&#163;1,000 Prize Draw</td>
                                </tr>
                                <tr>
                                    <td>91&#45;100</td>
                                    <td>&#163;1,000 Prize Draw</td>
                                </tr>
                            </Styled.RolloverTable>
                        </li>
                        <li>
                            Prize draw winners will be randomly allocated
                            through a random generator programme.
                        </li>
                        <li>
                            Delivery of the Prize will be managed by Prize
                            Shark, a third party supplier. Data will be shared
                            with Prize Shark solely to the extent necessary for
                            the purpose of fulfilment of the Prize. Prize Shark
                            will also be in touch via email or phone call.
                            Customers are responsible for ensuring the contact
                            details on their account are up to date.
                        </li>
                        <li>
                            Winners will be contacted no later than four weeks
                            following the end of the Super 6 round containing
                            the correct prediction.{' '}
                        </li>
                        <li>
                            The winner will be notified by email to the email
                            address registered on their account. Customers are
                            responsible for ensuring the contact details on
                            their account are up to date.
                        </li>
                        <li>
                            If the Promoter has been unable to contact the
                            winner of the Prize within 14 days of that game
                            period or competition ending the winner shall
                            forfeit the prize and the Promoter can expire the
                            prize.{' '}
                        </li>
                        <li>
                            Once a winner has responded to the Promoter and
                            verified their details to the Promoter&apos;s
                            satisfaction, the winner will receive their prize
                            within 28 days.
                        </li>
                        <li>
                            For players located in Ireland, all prizes will be
                            at an exchange rate of &#163;1:&#8364;1.
                        </li>
                        <li>
                            No entries on behalf of any third parties shall be
                            accepted.
                        </li>
                        <li>
                            The Promoter has the right, at their reasonable
                            discretion, to alter, amend or terminate this
                            competition acting reasonably and with prior notice.
                        </li>
                        <li>
                            If any provision or part&#45;provision of these
                            terms and conditions are or becomes invalid, illegal
                            or unenforceable, it shall be deemed modified to the
                            minimum extent necessary to make it valid, legal and
                            enforceable. If such modification is not possible,
                            the relevant provision or part&#45;provision shall
                            be deemed deleted. Any modification to or deletion
                            of a provision or part&#45;provision under this
                            clause shall not affect the validity and
                            enforceability of the rest of these terms and
                            conditions.
                        </li>
                        <li>
                            The Competition is co&#45;promoted by Sky UK Limited
                            of Grant Way, Isleworth, Middlesex, TW7 5QD
                            &#40;&quot;
                            <strong>Sky</strong>&quot;&#41; and Hestview Limited
                            &#40;trading as Sky Games&#41; with a registered
                            office address of One Chamberlain Square Cs,
                            Birmingham, United Kingdom, B3 3AX. &#40;Sky and Sky
                            Games together being, the &quot;
                            <strong>Promoter</strong>&quot;&#41;.
                        </li>
                    </Styled.OrderedList>
                </li>
                <li>
                    <p>Jamie Carragher Monthly Head-to-Head Challenge</p>
                    <Styled.OrderedList>
                        <li>
                            This prize will be available for the month September
                            2024, October 2024 and November 2024 giving three
                            chances to win the jackpot, once per month.
                        </li>
                        <li>
                            Players are automatically opted into Jamie Carragher
                            Monthly Head-to-Head challenge via the Website or
                            Mobile Apps prior to the deadline for submitting
                            entries for that round &#40;&quot;Head-to-Head
                            Challenge&quot;&#41;.
                        </li>
                        <li>
                            Once predictions have been submitted for the given
                            month players shall automatically enter the
                            Head-to-Head Challenge prize draw if they obtain a
                            higher number of points than Jamie Carragher for the
                            given month &#40;calculated as per clause 5.4&#41;.
                        </li>
                        <li>
                            The Head-to-Head Challenge Prize shall be awarded to
                            players who are placed into the Head-to-Head
                            Challenge prize draw and selected at random by a
                            computer program within 14 days after the completion
                            of the month.
                        </li>
                        <li>
                            The Jamie Carragher Monthly Head-to-Head Challenge
                            prize is &#163;50,000 cash, of which there will be
                            one winner.
                        </li>
                        <li>
                            For players located in Ireland, all prizes will be
                            at an exchange rate of &#163;1:&#8364;1.
                        </li>
                        <li>
                            Head-to-Head Challenge Prizes will be credited
                            within four weeks of verification of the winning
                            player in accordance with the verification process
                            detailed in clause 9 &#40;Winners and Entrants
                            Verification&#41;.
                        </li>
                        <li>
                            The Head-to-Head opponent may change their
                            predictions before the closing time for each round
                            via the Website or Mobile Apps. Once a round has
                            closed for entries, the Head-to-Head opponent shall
                            not change their predictions.
                        </li>
                        <li>
                            The Promoter reserves the right, acting reasonably
                            to change, withdraw or increase the availability of
                            and/or value of prizes throughout the Promotional
                            Period at any time.
                        </li>
                    </Styled.OrderedList>
                </li>
            </Styled.OrderedList>
        </li>
    </>
);

export default Prizes;
